
.cart {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 45px;
}

.cart-total {
    width: 98%;
    margin: 15px auto;
    box-sizing: border-box;
}

.bttn-small {
    height: 50px;
    font-size: 24px;
}
.remove {
    text-decoration: underline;
}
.cartvector {
    height: 24px;
    font-family: 'Neue Helvetica W01', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 500;
    text-decoration: underline;

}
.cartvector p {
    right: 10px;
}

.checkout {
    background-color: #f6f9fc;
}

.checkout * {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.checkout form {
    max-width: 496px !important;
    position: relative;
}

.checkout .container {
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 3px;
}

.checkout fieldset {
    border-style: none;
    padding: 5px;
    margin-left: -5px;
    margin-right: -5px;
    background: rgba(18, 91, 152, 0.05);
    border-radius: 8px;
}

.checkout fieldset legend {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 13px;
    color: #8898aa;
    padding: 3px 10px 7px;
}

.checkout fieldset legend + * {
    clear: both;
}

.checkout input,
.checkout button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
}

.checkout input:-webkit-autofill {
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
}

.checkout input {
    -webkit-animation: 1ms void-animation-out;
}

.checkout input::-webkit-input-placeholder {
    color: #9bacc8;
}

.checkout input::-moz-placeholder {
    color: #9bacc8;
}

.checkout input:-ms-input-placeholder {
    color: #9bacc8;
}

.checkout button {
    display: block;
    width: 100%;
    height: 37px;
    background-color: #6772e5;
    border-radius: 2px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}

.checkout button:active {
    background-color: #6772e5;
}

.checkout .error {
    color: #c23d4b;
    margin: 5px 0;
}

.checkout .github-corner {
    z-index: 100;
}

.checkout .cel .card {
    font-weight: 600;
}

.no-products {
    margin-top: 45px;
}

.cart-items-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.cart-price {
    width: 50%;
}

.pay-button {
    margin-top: 15px;
    height: 60px;
    width: 180px;
    border: none;
    background-color: black;
    border-radius: 3px;
    color: white;
    font-size: 14px;
}

.pay-button:disabled {
    background-color: lightgray;
}

.pay-button-alert {
    margin-top: 15px;
    font-size: 13px;
    color: red;
}