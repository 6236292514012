.shipping {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.shipping label {
  max-width: 280px;
  text-align: left;
  margin-bottom: 15px;
}

.shipping input {
  padding: 5px;
  margin-top: 5px;
  font-size: 14px;
  width: 100%;
  display: block;
}