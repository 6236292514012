* {
  box-sizing: border-box;
}

.product-info p, .product-info label, .product-titles label, .product-titles h1, .add-form button, .bttn-small, .product-1 p, .select-wrapper select, .cart-total, .cart h1{
  font-family: 'Neue Helvetica W01', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.high {
  font-weight: 700;
}
.medium {
  font-weight: 500;
}
.low {
  font-weight: 400;
}

.main {
  display: flex;
  max-width: 960px;
}

.App {
  text-align: center;
  width: 100%;
  /* max-width: 960px; */
}


.home-top{
  width: 100vw;
  height: calc(100vh - 80px);
  display: inline-block;
  box-sizing: border-box;
  padding-top: 32px; 
  overflow: scroll;
}
.home-top img{
  width: 50vw;
  margin: 0 auto;
}
.button-container{
  position: absolute;
  bottom: 108px;
}
.red-button{
  margin: 0 auto;
  text-align: center;
  width: 128px;
  height: 63px;
  background: #FD0004;
  border-radius: 122px;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.16);
  transition: 250ms;
  margin-top: 21vh;
  border: none;
  
}
.red-button:hover, .about-rectangle p:hover{
  cursor: pointer;
  transform: scale(1.05);
}
.about-rectangle{
  position: absolute;
  width: 100%;
  height: 108px;
  bottom: 0;
  background: linear-gradient(180deg, rgba(56, 56, 56, 0) 0%, rgb(192, 192, 192) 100%);
}
.about-rectangle p{
  position: absolute;
  width: 104px;
  height: 34px;
  left: 16px;
  top: 56px;
  display: flex;
  justify-content: space-between;
  transition: 300ms;
}

.link-text{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 590;
  font-size: 28px;
  line-height: 34px;
  color: #FFFFFF;
}
#dark{
  color: #484848;
}
.link-text img{
  margin-left: 8px;
}
.about{
  position: absolute;
  top: 100vh;
  width: 100%;
  height: 100vh;
  text-align: left;
  padding: 48px 16px;
  box-sizing: border-box;
}
hr{
  width: 100%;
}
.about-body{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.about-body p {
  font-family: PT Sans, 'Sans Serif';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #484848;
  width: 100%;
  padding-top: 16px;
}
.about img{
  width: 100%;
  box-shadow: 0 1px 2px rgba(34, 25, 25, 0.4);
}
.shop {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 30px 80px;
}
.hamburger{
  position: fixed;

}
.nawmanlogo {
  height: 52px;
}
.hamburger{
  top: 16px;
  right: 16px;
}
.hamburger div{
  display: block;
  width: 32px;
  height: 2px;
  margin: 8px;
  background-color: black;
  transition: 450ms cubic-bezier(.43,.44,.15,1.5),background .3s ease;
}
.one{
  transform: translate3d(0,10px,0) rotate(45deg) scale(1.1);
 }
 .two{
     opacity: 0;
 }
 .three{
     transform: translate3d(0,-10px,0) rotate(-45deg) scale(1.1);
 }
.shop-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: 80px;
  background: #fff;
  z-index: 2;
}
.nav, .hidden {
  box-sizing: border-box;
  transition: 250ms ease;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  font-family: 'Neue Helvetica W01', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  box-sizing: border-box;
  padding-top: 40px;
}
.product-info p{
  font-family: 'Neue Helvetica W01', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  
}
.nav {
  position: sticky;
  top: 80px;
  height: 80vh;
  background: #fff;
  border: solid 1px black;
  max-width: 170px;
  padding: 0 15px;
  line-height: 20px;
  justify-content: flex-start;
  padding-top: 80px;
}

.hidden {
  width: 160px;
  height: 700px;
  overflow: hidden;
  transition: 250ms ease;
  border: solid 1px black;
  justify-content: flex-start;
  padding-top: 80px;
}
.product-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.product-card {
  width: 120px;
}
.product-card img {
  width: 100%;
  height: 160px;
  margin-bottom: 16px;
}
.product-info {
  position: relative;
  width: 60%;
  margin-top: 15px;
  box-sizing: border-box;
  text-align: left;
  padding-left: 8px;
}

@media only screen and (min-width: 767px) {
  /* For general iPad layouts */
  .about-body p{
    width: 60%;
    margin: 0 16px;
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .about-body img{
    width: 30%;
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    margin-left: 5%;
  }
  
  .home-top img{
    width: 300px;
  }
  .hamburger {
    display: none;
  }
}
@media only screen and (min-width: 568px) {
  @media only screen and (max-height: 610px){

    .home-top img {
      width: 20%;
    }
  }
  /* sideways phones, doesnt look great */
}

@media only screen and (min-width: 1260px) {
  /* image in about gets too big here */
  @media only screen and (min-height: 860px){

    .about-body img{
      width: 25%
    }
    /* .red-button {
      margin-top: 320px;
    } */
  }
}
