.product {
    box-sizing: border-box;
    padding-top: 64px;
    width: 100%;
}
.product-titles, .image-container, .add-form {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px; 
}
.product-titles {
    text-align: left;
}
.product-titles h1, .italic {
    font-weight: 700;
    font-style: oblique;
    font-size: 1.5em;
}
.product-titles label, .product p, .product-info p {
    font-weight: 700;
    font-style: normal;
}
.image-container img {
    max-height: 460px;
    max-width: 100%;
}
.product-variants {
    margin-right: 2%;
    margin-top: 0;
    width: 48%;
}
.select-wrapper{
    position: relative;
    height: 100%;
    box-sizing: border-box; 
}
.add-form{
    display: flex;
    margin: 16px 0;
}
.add-form select {
    box-sizing: border-box;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 2px solid #000;
    border-radius: 0;
    background-color: transparent;
    padding: 6px 10px;
    padding-right: 32px;
    line-height: 1;
    height: 100%;
}
.select-wrapper::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    right: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 4px 0 4px;
    border-color: #000 transparent transparent transparent;
}
.add-form button, .bttn-small{
    width: 50%;
    margin: 0;
    line-height: 1;
    color: #fff !important;
    background-color: #000 !important;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    text-align: center;
    padding: 6px 10px;
    border: 2px solid #000;
    font-weight: 700;
    font-style: normal;
}
